@import '../../../src/scss/mixins/resolutionMixins';

.wavePosition {
  position: fixed;
  width: 100vw;
  z-index: 0;
  bottom: 25%;
  transition: bottom 1s ease-in-out;
  @include for-size(phone-only) {
    bottom: 50%;
  }
}

.moveBottom {
  transition: bottom 1s ease-in-out;
  bottom: 0;
  @include for-size(phone-only) {
    bottom: 35px;
  }
}

.parallax > use {
  animation: move-forever 1s linear infinite;
  &:nth-child(1) {
    animation-delay: -30s;
    animation-duration: 15s;
  }
  &:nth-child(2) {
    animation-delay: -15s;
    animation-duration: 30s;
  }
  &:nth-child(3) {
    animation-delay: -30s;
    animation-duration: 45s;
  }
}

@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
