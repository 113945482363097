@mixin fade-in-out($time: 500ms) {
  animation: enterFade $time ease-in-out;
  @keyframes enterFade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin fade-in-out-scale() {
  animation: enterFadeScale 500ms ease-in-out;
  @keyframes enterFadeScale {
    0% {
      opacity: 0;
      transform: scale(0.95);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@mixin slide-in($time: 200ms) {
  animation: enterFade $time ease-in-out;
  @keyframes enterFade {
    0% {
      opacity: 0;
      transform: translateX(30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@mixin slide-up($time: 200ms) {
  animation: enterFade $time ease-in-out;
  @keyframes enterFade {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
