/**
 * Bootstrap overrides
 */
$enable-shadows: false;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-transition: flex 0.15s ease-in-out, color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

