@import "../../scss/mixins/resolutionMixins";

.projectCard {
  background-color: var(--panel-bg-color);
  padding: 0;

  .button {
    width: 115px;
    font-size: 14px;
  }

  .sectionHeader {
    color: var(--text-primary);
    font-weight: 800;
  }

  .showAll {
    transition: color 100ms ease-in-out;
    cursor: pointer;
    font-size: 14px;
    color: var(--text-body);
    &:hover {
      color: var(--text-body-darker-20);
    }
  }

  .profileSection {
    color: var(--text-body);
  }

  .noCandidate {
    height: 50px;
    color: var(--text-placeholder);
  }

  .container {
    padding: 0 15px;
    @include for-size(phone-only) {
      padding: 0;
    }
  }
}
