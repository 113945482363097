@import "../../scss/mixins/resolutionMixins";
@import "../../scss/animation";

.content {
  width: 100%;
  padding-top: 10px;
  @include fade-in-out();
}

.splitSection {
  display: flex;
  justify-content: space-between;
  @include for-size(phone-only) {
    display: block;
  }
}

.countryCode {
  min-width: 120px !important;
  @include for-size(phone-only) {
    min-width: 100px !important;
  }

  :global(.select__menu) {
    width: 250px;
  }
}

.inputStyle {
  width: 49% !important;
  @include for-size(phone-only) {
    width: 100% !important;
  }
}

.selectContainerStyle {
  :global(.select__menu) {
    z-index: 2 !important;
  }
  width: 49% !important;
  & > div {
    min-width: unset !important;
  }
  @include for-size(phone-only) {
    width: 100% !important;
  }
}

.slider {
  margin-top: -20px !important;
  width: 96% !important;
  margin-left: 9px !important;
}

.sliderValue {
  color: var(--primary-color);
  margin-right: 5px;
}

.forgotPasswordText {
  transition: color 100ms ease-in-out;
  cursor: pointer;
  &:hover {
    color: var(--text-primary);
  }
}
