@import "../../scss/mixins/resolutionMixins";

.dashboardPage {
  padding-bottom: 20px;
  .overviewLeftSection {
    flex-grow: 1;
  }

  .overviewRightSection {
    display: block;
    width: 30%;
    margin-left: 20px;
    @include for-size(phone-only) {
      display: none;
    }
  }
}
