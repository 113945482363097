@import "../../../../scss/mixins/resolutionMixins";

.header {
  display: flex;
  justify-content: space-between;

  .overview {
    width: 49.5%;
  }

  .setup {
    width: 49.5%;
  }

  @include for-size(phone-only) {
    display: none;
  }
}

.tabs {
  display: none;
  @include for-size(phone-only) {
    display: block;
  }
}

.phoneButton {
  margin-right: 1px;
  border-radius: 3.5px 0 0 3.5px !important;
  @include for-size(phone-only) {
    font-size: 14px;
    width: 115px;
  }
}

.functionBar {
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  .title {
    font-size: 16px;
    color: var(--white-color);
    padding-left: 10px;
  }

  .search {
    position: relative;
    @include for-size(phone-only) {
      position: absolute;
      width: 30px;
      transition: width 500ms;
      right: 0;
    }
  }

  .mobileSearch {
    @include for-size(phone-only) {
      width: calc(55vw - 35px);
      transition: width 500ms;
    }
  }

  .dropdownButton {
    @include for-size(phone-only) {
      height: 31px;
    }
  }
}
