@import "../../../src/scss/mixins/resolutionMixins";
@import "../../../src/scss/animation";

.pagebase {
  background-image: linear-gradient(
    var(--tertiary-color),
    var(--primary-color)
  );
  background-attachment: fixed;
  @include for-size(phone-only) {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .pagebaseContainer {
    font-family: var(--font-family);
    height: calc(100vh - 80px);
    display: flex;
    z-index: 1;
    position: relative;
    width: calc(100% - 270px);
    margin-left: 255px;
    padding: 0 10px;
    overflow-x: hidden;
    margin-top: 20px;

    // For mobile viewpoint heights, it's vh is calculate differently
    @include for-size(phone-only) {
      height: 100%;
      width: 100%;
      margin-left: unset;
      padding: 0;
      overflow-x: unset;
    }
    @include for-size(phone-only-landscape) {
      height: 100%;
      overflow: auto;
    }

    .pagebaseContent {
      width: 100%;
      flex-grow: 1;
      z-index: 10;
      @include for-size(phone-only) {
        margin-top: 60px;
      }
    }
  }

  .noSidebar {
    margin: 0 auto;
    height: calc(100vh - 60px);
    width: unset;
    @include for-size(phone-only) {
      height: unset;
    }
  }
}
