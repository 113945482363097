.modalHeader {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: bold;
  color: var(--text-primary);
  align-items: center;
  padding: 10px 15px;
  border-bottom: solid var(--border-color) 0.5px;

  .headerContent {
    flex: 1;
    font-weight: 700;
    text-align: center;
  }

  .close {
    flex-shrink: 0;
    cursor: pointer;
    font-size: 24px;
    font-weight: 700;
    color: rgba(var(--text-primary-rgb), 0.5);

    &:hover {
      color: var(--text-primary);
    }
  }
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px;
}

.modal {
  :global(.modal-dialog) {
    border-radius: 20px;
    box-shadow: 0 6px 30px rgba(var(--shadow-color-rgb), 0.2);

    :global(.modal-content) {
      border: none;
      border-radius: 20px;
      font-family: var(--font-family);
      :global(.modal-body) {
        padding: 10px 15px;
      }
    }
  }
}

.footerBtn {
  min-width: 80px;
}
