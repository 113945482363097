.projectTitle {
  padding: 20px;
  .circle {
    height: 70px;
    width: 70px;
    border-radius: 80px;
    background-color: var(--white-color);
    font-weight: 700;
    font-size: 34px;
    color: var(--text-body);
  }
  .textSection {
    font-size: 18px;
    font-weight: 700;
    color: var(--text-body);
  }
  .subtitle {
    font-size: 14px;
  }
}
