@import "../../../src/scss/mixins/resolutionMixins";

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 0.3fr));
  grid-gap: 40px;
  @include for-size(phone-only) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 0.3fr));
    grid-gap: 30px;
  }

}
