.waveProgressWrapperOuter {
  width: var(--circle-progress-bar-size);
  height: var(--circle-progress-bar-size);
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(.95);

}

.waveProgressWrapperInner {
  display: flex;
  align-items: center;
  justify-items: center;
  width: calc(var(--circle-progress-bar-size) - 10px);
  height: calc(var(--circle-progress-bar-size) - 10px);
  position: absolute;
  border: 0.18rem solid #fff;
  border-radius: 50%;
  z-index: 5;
  overflow: hidden;
  background: #fff;
}

.wrapper {
  width: calc(var(--circle-progress-bar-size) - 10px);
  height: calc(var(--circle-progress-bar-size) - 10px);
  position: absolute;
  bottom: 0;
  border-radius: 35%;
}

.waveProgressWrapperOuter .wrapper {
  background:  var(--natural-color-light-80);
}

.far {
  z-index: 2;
  opacity: 0.8;
  animation: animateWave 1.5s ease-out, wave 14s linear infinite;
}

.near {
  z-index: 3;
  opacity: 0.8;
  animation: animateWave 1s ease-in-out,wave-reverse 16s linear infinite;
}

.waveProgressWrapperOuter .far, .waveProgressWrapperOuter .near {
  background: var(--accent-color);
  background: radial-gradient(var(--accent-color) 25%, var(--accent-color-light-60) 100%);
}

.waveProgress {
  //animation: animateWave 1s ease-in-out;
  width: 200%;
  height: 200%;
  border-radius: 40%;
  position: absolute;
  top: 110%;
  left: -50%;
}


@keyframes wave {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes wave-reverse {
  0% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}

.content {
  position: relative;
  z-index: 10;
  width: 100%;
}

@keyframes animateWave {
  from {
    top: 150%;
  }
}
