.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 30px;
  user-select: none;
}

.breadcrumbItem {
  color: var(--white-color);
  display: flex;
  align-items: center;
  font-weight: 400;

}

.last {
  font-weight: 700 !important;
}


.clickable {
  transition: color 100ms ease-in-out;
  cursor: pointer;
  user-select: unset;
  &:hover {
    color: var(--white-color-dark-20);
  }
}
