.customTabs {
  color: var(--contrast-color);

  :global(.nav.nav-tabs) {
    margin-bottom: 5px;
    position: relative;
    flex-wrap: nowrap;
    border-bottom: none;

    & :global(.nav-item) {
      &:global(.active) {
        & :global(.nav-link) {
          color: var(--white-color);
        }
      }
    }

    & :global(.nav-link) {
      padding: 8px 0;
      margin: 0 16px;
      cursor: pointer;
      white-space: nowrap;
      color: var(--white-color-dark-40);
      border: none;

      &:hover {
        color: var(--primary-color);
      }

      &:global(.disabled) {
        cursor: not-allowed !important;
        color: rgba(var(--contrast-color-rgb), 0.5) !important;
      }
    }
  }
}
