@import "../../scss/mixins/resolutionMixins";

.testList {
  display: flex;
  width: 100%;
  background-color: var(--natural-color-light-80);
  border-radius: 15px;
  padding: 10px;
  position: relative;
  margin: 10px 0;

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    left: -15px;
    top: 31px;
    border-radius: 80px;
    background: var(--accent-color);
  }

  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: -18px;
    top: 28px;
    border-radius: 50px;
    background: transparent;
    border: 1px solid var(--border-color);
  }

  .firstContent {
    width: 55%;
    @include for-size(phone-only) {
      width: 40%;
    }
  }

  .secondContent {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 700;
    width: 30%;
  }

  .thirdContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    @include for-size(phone-only) {
      width: 30%;
    }
  }

  .line {
    height: 70px;
    width: 1px;
    background-color: var(--border-color);
    position: absolute;
    left: -12.5px;
    top: 39px;
  }

  .button {
    font-size: 12px;
  }

  &.isCompleted:after {
    background: var(--status-success);
  }

}
