.title{
  font-size: 16px;
  color: var(--white-color);
}

.container{
  margin: 5px 0;
  padding: 8px;
  background-color: var(--table-head-bg-color);
  border-radius: 20px;
  height: 125px;
}
