.loginContainer {
  margin-top: 20px;
  .mainText {
    color: var(--white-color);
    font-size: 18px;
  }
  .card {
    max-width: 500px;
    flex-direction: column;
  }
}
