// Z-depth drop-shadow
// Ref: https://github.com/Dogfalo/materialize/blob/master/sass/components/_global.scss#L53

$shadow-color: var(--shadow-color-rgb) !default;

.z-depth-0 {
  box-shadow: none;

  &.hover-able {
    transition: box-shadow 0.25s;

    &:hover {
      box-shadow: 0 8px 17px 0 rgba($shadow-color, 0.2), 0 6px 20px 0 rgba($shadow-color, 0.19);
    }
  }
}

.z-depth-1 {
  @extend .z-depth-0;

  box-shadow: 0 2px 2px 0 rgba($shadow-color, 0.14), 0 1px 5px 0 rgba($shadow-color, 0.12),
    0 3px 1px -2px rgba($shadow-color, 0.2);
}

.z-depth-1-half {
  @extend .z-depth-0;

  box-shadow: 0 3px 3px 0 rgba($shadow-color, 0.14), 0 1px 7px 0 rgba($shadow-color, 0.12),
    0 3px 1px -1px rgba($shadow-color, 0.2);
}

.z-depth-2 {
  @extend .z-depth-0;

  box-shadow: 0 4px 5px 0 rgba($shadow-color, 0.14), 0 1px 10px 0 rgba($shadow-color, 0.12),
    0 2px 4px -1px rgba($shadow-color, 0.3);
}

.z-depth-3 {
  @extend .z-depth-0;

  box-shadow: 0 6px 10px 0 rgba($shadow-color, 0.14), 0 1px 18px 0 rgba($shadow-color, 0.12),
    0 3px 5px -1px rgba($shadow-color, 0.3);
}

.z-depth-4 {
  @extend .z-depth-0;

  box-shadow: 0 8px 10px 1px rgba($shadow-color, 0.14), 0 3px 14px 2px rgba($shadow-color, 0.12),
    0 5px 5px -3px rgba($shadow-color, 0.3);
}

.z-depth-5 {
  @extend .z-depth-0;

  box-shadow: 0 16px 24px 2px rgba($shadow-color, 0.14), 0 6px 30px 5px rgba($shadow-color, 0.12),
    0 8px 10px -5px rgba($shadow-color, 0.3);
}

// drop-shadow bottom only
.z-depth-b-1 {
  @extend .z-depth-0;

  box-shadow: 0 2px 2px 0 rgba($shadow-color, 0.3);
}

.z-depth-b-1-half {
  @extend .z-depth-0;

  box-shadow: 0 3px 3px 0 rgba($shadow-color, 0.3);
}

.z-depth-b-2 {
  @extend .z-depth-0;

  box-shadow: 0 4px 5px 0 rgba($shadow-color, 0.3);
}

.z-depth-b-3 {
  @extend .z-depth-0;

  box-shadow: 0 6px 10px 0 rgba($shadow-color, 0.3);
}

.z-depth-b-4 {
  @extend .z-depth-0;

  box-shadow: 0 8px 10px 1px rgba($shadow-color, 0.3);
}

.z-depth-b-5 {
  @extend .z-depth-0;

  box-shadow: 0 16px 24px 2px rgba($shadow-color, 0.3);
}

// Just to introduce name
.z-depth-focus {
  @extend .z-depth-b-1-half;
}
