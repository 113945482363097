@import "../../scss/dropShadow";
@import "../../scss/mixins/resolutionMixins";

.dropdown {
  :global(.dropdown-menu) {
    border: none;
    padding: 2px;
    @extend .z-depth-3;

    @include for-size(phone-only) {
      transform: translate3d(-16px, 34px, 0px) !important;
    }
  }

  :global(.dropdown-header) {
    user-select: none;
  }

  :global(.dropdown-item) {
    border-radius: 5px;
    font-size: 14px;
    text-align: left;

    &:hover {
      background-color: rgba(var(--primary-color-rgb), 0.1);
    }

    &:active {
      background-color: var(--primary-color);
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      user-select: none;
      cursor: not-allowed;
    }
  }

  .dropdownItemLink {
    &:hover {
      text-decoration: none;
    }
  }
}
