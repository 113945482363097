.dropdownButton {
  .chevronDownIcon {
    position: relative;
    font-size: 15px;
    top: 2px;
  }
}
.caretBtn {
  background: var(--secondary-color) !important;
  border-left: 0.3px solid var(--secondary-color) !important;
  border-radius: 0 3.5px 3.5px 0 !important;
}

.button{
  border-radius: 3.5px 0 0 3.5px !important;
}
