.candidateProgressBar{
  position: relative;
  display: grid;
  width: 90px;

  .text{
    text-align: center;
    font-size: 12px;
    width: 70px;
  }

  .circle{
    position: absolute;
    right: 8px;
    top: 12px;
  }

  .fillComplete{
    fill: var(--candidate-progress-complete-color);
  }

  .fillIncomplete{
    fill: var(--candidate-progress-incomplete-color)
  }

  .strokeComplete{
    stroke: var(--candidate-progress-complete-color);
  }

  .strokeIncomplete{
    stroke: var(--candidate-progress-incomplete-color)
  }
}
