@import "../../../../../scss/mixins/resolutionMixins";

.animatedDiv {
  position: fixed;
  right: -5px;
  top: 30%;
  z-index: 11;

  .addCandidateContainer {
    background-color: var(--panel-bg-color) !important;
    width: 25% !important;
    min-width: 350px;
    color: var(--text-body);
    transition: transform ease-in-out 1s;
    transform: translateX(0);
    padding: 0;
    border-radius: 20px 0 20px 20px;
    box-shadow: 0 1px 10px 0 var(--dark-grey-color);

    .header {
      padding: 10px 20px;

      .dropdown{
        :global(.dropdown-menu){
          left: 140px !important;
          transform: translate3d(-160px, 24px, 0px)!important;
          @include for-size(phone-only) {
            left: 0 !important;
            transform: translate3d(-31px, 24px, 0px)!important;
          }
        }
      }
    }

    .sideFont {
      font-size: 14px;
      padding-left: 5px;
    }

    .uploadCandidate{
      cursor: pointer;
      font-size: 14px;
      padding-left: 5px;
      text-decoration: underline;
      text-underline: var(--text-body);
    }

    .addCandidateForm {
      background-color: var(--white-color) !important;
      padding: 10px 25px;
      border-radius: 0 0 0 20px;
    }

    .buttonWidth {
      width: 120px;
    }
  }
}
