.title {
  font-size: 18px;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  margin-right: 10px;
  font-weight: bold;
  color: var(--white-color);
}

.subTitle {
  font-size: 14px;
  font-weight: bold;
  color: var(--natural-color);
}

.code {
  background-color: lightgray;
  border-radius: 4px;
  padding: 0 4px;
}

.panel {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0px 0 10px;
  width: 100%;
}


.backgroundWhite{
  padding: 10px 20px;
  background: var(--white-color);
  border-radius: 5px;
}


.card {
  justify-content: left;
}
