.setupStatus {
  padding: 5px;

  .statusTitle {
    font-weight: 900;
  }

  .statusCondition {
    color: var(--text-body);
    font-size: 16px;
    font-weight: 600;
  }

  .icon {
    margin: 5px;
    font-size: 12px;
  }
}
