@import '../../scss/mixins/resolutionMixins';
@import "../../../src/scss/dropShadow";
.cardBox {
  background-color: var(--white-color);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 20px;
  @extend .z-depth-3;
}
