.progressBarDescription {
  width: 100%;
  .title {
    font-weight: 900;
    font-size: 18px;
    color: var(--text-body);
    padding-left: 5px;
  }

  .progressBar {
    width: 95%;
    border-radius: 20px;
    height: 8px;

    :global(.progress-bar) {
      background-color: var(--primary-color);
      transition: width 1s linear;
      border-radius: 10px;
    }
  }

  .progressValue {
    color: var(--primary-color);
    span {
      font-weight: 900;
    }
  }

  .description {
    padding-left: 5px;
    color: var(--text-placeholder);
    font-size: 14px;
    padding-top: 3px;
  }
}
