@import "../../scss/mixins/resolutionMixins";
.testListTable {
  width: 100%;

  .header {
    font-size: 14px;
    padding-bottom: 10px;

    .headerSecondContent {
      width: 28%;
      @include for-size(phone-only) {
        width: 43%;
      }
    }

    .headerThirdContent {
      width: 18%;
    }

  }
}
