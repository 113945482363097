.inputLabel {
  position: relative;
  color: var(--text-primary);
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 700;
}

.inputLabelPadding {
  padding-left: 5px;
}

.asteriskLabel {
  top: 1px !important;
  left: -2px !important;;
}
