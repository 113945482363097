.uploadImageMain {
  transition: all 100ms ease-in-out;
  color: var(--text-body);
  width: 200px;
  margin: 10px 0;

  .fileSelectedText {
    height: 20px;
    margin-top: 10px;
    font-size: 12px;
  }
}
