// lighted or darken color for custom css properties
@mixin generateShade($name, $hex) {
  #{$name}: $hex;
  #{$name}-rgb: red($hex), green($hex), blue($hex);
  #{$name}-light-20: scale-color($hex, $lightness: 20%);
  #{$name}-light-20-rgb: red(scale-color($hex, $lightness: 20%)),
    green(scale-color($hex, $lightness: 20%)), blue(scale-color($hex, $lightness: 20%));
  #{$name}-light-40: scale-color($hex, $lightness: 40%);
  #{$name}-light-40-rgb: red(scale-color($hex, $lightness: 40%)),
    green(scale-color($hex, $lightness: 40%)), blue(scale-color($hex, $lightness: 40%));
  #{$name}-light-60: scale-color($hex, $lightness: 60%);
  #{$name}-light-60-rgb: red(scale-color($hex, $lightness: 60%)),
    green(scale-color($hex, $lightness: 60%)), blue(scale-color($hex, $lightness: 60%));
  #{$name}-light-80: scale-color($hex, $lightness: 80%);
  #{$name}-light-80-rgb: red(scale-color($hex, $lightness: 80%)),
    green(scale-color($hex, $lightness: 80%)), blue(scale-color($hex, $lightness: 80%));
  #{$name}-dark-20: scale-color($hex, $lightness: -20%);
  #{$name}-dark-20-rgb: red(scale-color($hex, $lightness: -20%)),
    green(scale-color($hex, $lightness: -20%)), blue(scale-color($hex, $lightness: -20%));
  #{$name}-dark-40: scale-color($hex, $lightness: -40%);
  #{$name}-dark-40-rgb: red(scale-color($hex, $lightness: -40%)),
  green(scale-color($hex, $lightness: -20%)), blue(scale-color($hex, $lightness: -40%));
}

@mixin generateColor($name, $hex) {
  #{$name}: $hex;
  #{$name}-rgb: red($hex), green($hex), blue($hex);
}
