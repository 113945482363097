@import "../../../../../scss/mixins/resolutionMixins";

.table {
  :global(.ReactTable .rt-tbody) {
    height: calc(100vh - 400px) !important;
  }
  .profileName {
    color: black;
    display: block;
    @include for-size(phone-only) {
      display: block !important;
    }
  }

  .cursor {
    cursor: pointer;
  }
}
