@import "../../../../../src/scss/mixins/resolutionMixins";

.additionalContent {
  width: 100%;
  text-align: center;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
  color: var(--text-body);
}

.select {
  @include for-size(phone-only) {
    min-width: 200px !important;
  }
}

.noData {
  height: calc(100vh - 345px) !important;
}
