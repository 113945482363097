@import '../../scss/mixins/resolutionMixins';

.header {
  padding-top: 10px;
  padding-bottom: 15px;
  @include for-size(phone-only) {
    background-color: var(--tertiary-color);
    border-bottom: 1px solid var(--white-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100%;
  }
}

.logo {
  display: flex;
  justify-content: space-between;
  @include for-size(phone-only) {
    justify-content: center;
  }
  font-size: 25px;
  color: var(--white-color);
}

.profilePhone {
  @include for-size(phone-only) {
    position: absolute;
    right: 25px;
    top: 19px;
    font-size: 20px;
  }
}

.hamburgerSection {
  display: none;
  @include for-size(phone-only) {
    display: block;
    position: absolute;
    left: 25px;
    top: 19px;
    font-size: 20px;
  }
}
