.reportModalHeader {
  .name {
    font-size: 25px;
    font-weight: 900;
  }

  .overall {
    font-size: 12px;
  }

  .textCenter {
    display: flex;
    justify-content: center;
  }

  .header {
    position: absolute;
    top: 10px;
    width: 100%;
    text-align: center;
    left: 0;
    font-weight: 700;

    .headerText {
      font-weight: 700;
      color: var(--text-primary-light-20);
    }

    .icon {
      position: absolute;
      right: 20px;
      top: 0;
      font-size: 18px;
      color: var(--text-placeholder);
      cursor: pointer;
    }
  }
}
