@import "../../../src/scss/mixins/resolutionMixins";
@import "../../../src/scss/dropShadow";

.animatedDiv {
  position: fixed;
  z-index: 11;
  margin-top: 20px;
  width: 250px;
  @include for-size(phone-only) {
    margin-top: 60px;
    margin-left: 0px;
  }


  .sideNavigation {
    background-color: var(--panel-bg-color) !important;
    padding: 0 !important;
    width: 25% !important;
    min-width: 250px;
    height: calc((var(--vh, 1vh) * 100) - 100px);
    color: var(--text-body);
    margin-right: 20px;
    @extend .z-depth-5;

    @include for-size(phone-only) {
      transition: transform ease-in-out 1s;
      position: absolute;
      transform: translateX(0);
    }

    .topSection {
      height: 15%;
      margin: 20px;
    }

    .middleSection {
      height: 65%;
      overflow: auto;
    }

    .bottomSection {
      height: 20%;
    }

    &.mobileActive {
      @include for-size(phone-only) {
        position: absolute;
      }
    }
  }
}
