@import "../../../src/scss/mixins/resolutionMixins";

.paginationContainer {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  user-select: none;
  font-size: 14px !important;
  align-items: flex-end;
  font-weight: 500;
  .paginationTotal {
    flex-direction: row;
    @include for-size(phone-only) {
      flex-direction: column;
    }
  }

  .selectedText {
    margin-left: 20px;
  }

  .titleStyle {
    font-weight: 600;
    color: var(--primary-color);
    @include for-size(phone-only) {
      flex-direction: column;
    }
  }

  .tableFooterStyle {
    flex-grow: 1;
  }
}

.paginationNavBtn {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 5px;

  &:active {
    background-color: var(--border-color-dark-20);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    outline: none;
    background-color: var(--border-color);
  }

  &.hidden {
    visibility: hidden;
  }
}

.pageNumbersBtn {
  background-color: transparent;
  border: none;
  color: var(--contrast-color);
  cursor: pointer;
  height: 25px;
  border-radius: 5px;

  &:focus {
    outline: none;
  }

  &:hover {
    outline: none;
    background-color: var(--border-color);
  }

  &.active {
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
  }
}

.wrapperSection {
  position: relative;
  width: 230px;
  margin-bottom: -3px;

  @include for-size(phone-only) {
    width: 180px;
  }

  .wrapperCenter {
    width: 185px;
  }
}
