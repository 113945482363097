.inputMain {
  width: 100%;
  .input {
    padding: 4px 15px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    width: 100%;
    font-size: 14px;
    height: 38px;
    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .inputContainer {
    position: relative;
  }

  .rightAppendAddon {
    position: absolute;
    right: 14px;
    top: 11px;
    font-size: 12px;
    color: var(--dark-grey-color);
  }

  .error {
    line-height: 20px;
    min-height: 14px;
    color: var(--status-danger);
    font-size: 12px;
  }

  .inputError {
    border: 1px solid var(--status-danger);
  }
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}
