.card {
  transition: transform 500ms ease-in-out;
  display: flex;
  position: relative;
  padding-top: 40px;

  &.loading {
    transform: scale(0.95);
    pointer-events: none;
    user-select: none;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: calc(100% - 35px);
  background-color: var(--white-color);
  opacity: 0.5;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.progressBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 35px !important;
  border-radius: 15px 15px 0 0;
}

.progressText {
  position: absolute;
  top: 8px;
  font-weight: 800;
  color: var(--text-primary-light-20);
  font-size: 14px;
  margin-left: -20px;
  width: 100%;
  text-align: center;
}
