@import "../../../../../scss/mixins/resolutionMixins";

.projectOverview{
  justify-content: center;
  align-items: center;

  .titleContainer {
    width: 220px;
    @include for-size(phone-only) {
      width: 150px;
    }
  }
  .title{
    padding: 0 10px;
    color: var(--text-body);
  }

  .subtitle {
    padding: 0 10px;
    color: var(--text-body);
    font-size: 14px;
  }

  .firstLetterContainer{
    position: relative;
    width: 65px;
    height: 65px;
    background-color: var(--white-color);
    border-radius: 50%;

    .letter{
      font-size: 30px;
      font-weight: 900;
      position: absolute;
      top: 16%;
      left: 31%;
      color: var(--text-body);
    }

  }

}

.projectTitle {
  width: 300px;
}
