.select {
  position: relative;
  .subTitle {
    font-size: 14px;
    font-weight: bold;
    color: var(--natural-color);
    margin-bottom: 0;
  }

  .select {
    font-size: 14px;
    min-width: 250px;

    :global(.select__indicator-separator) {
      width: 0;
      background-color: transparent;
    }

    :global(.select__indicator.select__dropdown-indicator) {
      color: var(--primary-color);
    }
    :global(.select__control) {
      border-color: var(--border-color);
    }

    :global(.select__value-container) {
      padding: 4px 15px;
    }

    :global(.select__menu) {
      z-index: 2;
    }
  }

  .borderLess {
    :global(.select__control) {
      border-color: transparent;
      box-shadow: none;

      &:hover {
        border-color: transparent;
      }
    }
  }

  .borderError {
    :global(.select__control) {
      border: 1px solid var(--status-danger);
      box-shadow: none;
    }
  }

  .error {
    height: 14px;
    color: var(--status-danger);
    font-size: 12px;
  }
}
