@import "../../scss/mixins/resolutionMixins";

.icon {
  color: var(--primary);
  font-size: 20px;
  position: absolute;
  right: 7px;
  top: 10px;
}

.input{
  height: 34px !important;
  @include for-size(phone-only) {
    height: 32px !important;
  }
}

.searchIcon {
  font-size: 15px;
}
