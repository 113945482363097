.sliderStyle {
  position: relative;
  height: 40px;
  padding-top: 22px;
  margin: 10px;
  width: 100%;
}

.railStyle {
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: var(--natural-color-light-40);
  margin-top: 5px;
  cursor: pointer;
  box-sizing: border-box;
}

.handleStyle {
  transition: box-shadow ease-in-out 100ms;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 20px;
  margin-left: -9px;
  border-radius: 9px;
  cursor: pointer;
  background-color: var(--primary-color);
  border: var(--white) solid 2px;
  box-sizing: border-box;
  overflow: visible;
  z-index: 1;
  box-shadow: 0 2px 3px 0 var(--dark-grey-color);
  &:hover {
    box-shadow: 0 4px 5px 0 var(--dark-grey-color) !important;
  }
  &:active {
    box-shadow: 0 3px 4px 0 var(--dark-grey-color) !important;
  }
  &:hover > .handleValueStyle {
    opacity: 1;
  }
  &:active > .handleValueStyle {
    opacity: 1;
  }
}

.handleValueStyle {
  transition: opacity 100ms ease;
  opacity: 0;
  padding: 2px 4px;
  color: var(--primary-color);
  background-color: var(--panel-background-rgb);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  margin-top: -24px;
  height: 20px;
  box-sizing: border-box;
  font-size: 10px;
}

.trackStyle {
  position: absolute;
  top: 27px;
  height: 6px;
  background-color: var(--primary-color-dark-20);
  box-sizing: border-box;
  border-radius: 8px;
}

.opacity {
  div {
    opacity: 1 !important;
  }
}
