@mixin for-size($range) {
  $phone-lower-boundary: 400px;
  $phone-upper-boundary: 550px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == small-phone-only {
    @media (max-width: $phone-lower-boundary) {
      @content;
    }
  } @else if $range == small-phone-only-landscape {
    @media (max-width: #{$phone-lower-boundary - 1}) {
      @content;
    }
  } @else if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == phone-only-landscape {
    @media (max-height: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == small-desktop-height {
    @media (max-height: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) {
      @content;
    }
  }
}
