.navigationMenu {
  .navigationItem {
    transition: all ease-in-out 200ms;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin: 30px 0;
    padding-left: 30px;
    user-select: none;

    &.active {
      border-left: 5px solid var(--accent-color);
      padding-left: 18px;
      font-weight: 700;

    }

    &:hover {
      font-weight: 700;
    }
  }


}
