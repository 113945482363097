@import "../../scss/mixins/resolutionMixins";

.testList {
  .icon {
    @include for-size(phone-only) {
      display: none;
    }
  }


  .name {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }
  .status {
    font-size: 13px;
    color: var(--text-body);
  }
}
