@import '../mixins/themeMixins';

body.defaultLight {
  @include generateShade(--primary-color, #166995);
  @include generateShade(--secondary-color, #dd4064);
  @include generateShade(--tertiary-color, #1c2d74);
  @include generateShade(--accent-color, #E5B61C);
  @include generateShade(--natural-color, #9599a7);
  @include generateShade(--text-primary, #212121);
  @include generateShade(--text-body, #687B91);
  @include generateColor(--text-placeholder, #868686);
  @include generateColor(--text-inverted, #ffffff);

  @include generateColor(--bg-color, #ffffff);
  @include generateColor(--border-color, #C4C4C4);
  @include generateColor(--panel-bg-color, #E1EBF7);
  @include generateColor(--shadow-color, #333333);
  @include generateShade(--white-color, #ffffff);
  @include generateColor(--light-grey-color, #d3d3d3);
  @include generateColor(--dark-grey-color, #858585);

  @include generateColor(--status-new, #a5d9ff);
  @include generateColor(--status-info, #2cceeb);
  @include generateColor(--status-primary, #2660ff);
  @include generateShade(--status-success, #60D522);
  @include generateColor(--status-warning, #f8c106);
  @include generateShade(--status-danger, #eb1165);

  --box-shadow: 0 2px 5px -1px var(--shadow-color);

  //Input
  --input-border: #2684FF;
  --input-border-hover: #B3B3B3;

  //Gradient Color
  --gradient-color: linear-gradient(var(--primary-color), var(--secondary-color));
  --gradient-color-bg: linear-gradient(var(--tertiary-color), var(--primary-color));

  //Font
  --font-family: 'Quicksand', sans-serif;

  //Table
  --table-head-bg-color: #e1ebf8;
  --table-head-text-color: #687b91;
  --table-border-color: #000000;

  //Tooltip
  --tooltip-bg-color: #000000;
  --tooltip-border-color: #000000;

  //Candidate Status Progress
  --candidate-progress-incomplete-color: #d6d6d6;
  --candidate-progress-complete-color: var(--primary-color);

  //Message
  --panel-background: var(--bg-color);

  //Status Badges
  @include generateShade(--status-cyan-color, #a5d9ff);
  @include generateShade(--status-yellow-color, #f8c106);
  @include generateShade(--status-green-color, #01e301);
  @include generateShade(--status-light-blue-color, #14aeff);

  --status-blue-color: #2660ff;
  --status-red-color: #f41067;
  --status-orange-color: #ffa500;

  //Popover
  --popover-bg-color: #f7f7f7;
  --popover-bg-header-color: #ebebeb;
  --popover-border-color: #ebebeb;

  //ReportModal
  --report-background-color: #E1EBF7
}
