@import "../../../../scss/mixins/resolutionMixins";

.card {
  padding: 20px 30px;
  height: calc(100vh - 300px);
  @include for-size(phone-only) {
    height: unset;
  }

}

.headerText {
  color: var(--text-primary) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.contentSection {
  display: flex;
  justify-content: space-between;

  @include for-size(phone-only) {
    display: block;
  }

  .testOverview {
    width: 47%;
    font-size: 14px;
    padding-right: 20px;
    text-align: justify;
    @include for-size(phone-only) {
      width: 100%;
    }
  }

  .testList {
    width: 50%;
    @include for-size(phone-only) {
      width: 100%;
      padding-top: 30px;
    }
  }
}

.testTable {
  margin-top: -25px;
}


.header {
  display: flex;
  justify-content: space-between;

  .overview {
    width: 49.5%;
  }

  .setup {
    width: 49.5%;
  }

  @include for-size(phone-only) {
    display: none;
  }
}

.tabs {
  display: none;
  @include for-size(phone-only) {
    display: block;
  }
}

.confetti {
  position: fixed;
  bottom: 0;
  left: calc(55vw);
}
