.homepageContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.homepageContent {
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100% - 5px);
  padding: 0 10px 0 0;
}

.leftNav {
  flex-shrink: 0;
  overflow-y: auto;
  margin-left: 20px;
  width: 180px;
  right: 50px;
}

.leftNameItem {
  padding: 3px;
  a {
    transition: color 100ms ease-in-out;
    color: var(--primary-color);
    text-decoration: none;
    &:hover {
      color: var(--secondary-color)
    }
  }
}

.title {
  font-size: 18px;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  margin-right: 10px;
  font-weight: bold;
  color: var(--white-color);
}

.cardBody {
  height: 200px;
}


.darkBg {
  background-color: var(--text-primary);
}
