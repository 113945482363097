.reactTable {
  width: 100%;

  :global(.ReactTable) {
    border-radius: 5px;
    border: 0;

  }

  :global(.ReactTable .rt-table) {
    &::-webkit-scrollbar-track {
      -webkit-appearance: none;
      width: 5px;
      height: 5px;
      background-color:var(--white-color) !important;

      &:hover {
        background-color: rgba(var(--primary-color-rgb), 0.1);
      }
    }
  }

  :global(.ReactTable .rt-thead .rt-tr) {
    text-align: left;
    font-weight: 600;
    background-color: var(--table-head-bg-color);
    color: var(--table-head-text-color);
    border-radius: 10px 10px 0 0;
    padding: 0;
    font-size: 14px;
  }

  :global(.ReactTable .rt-thead.-header) {
    box-shadow: none;
  }

  :global(.ReactTable .rt-thead .rt-th) {
    border-right: none;
    padding-left: 5px;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header:last-child) {
    padding-left: 5px;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header:first-child) {
    padding-left: 10px;
  }

  :global(.ReactTable .rt-tbody) {
    height: 500px;
    overflow-x: hidden;
    background-color: var(--white-color);
  }

  :global(.ReactTable .rt-tbody .rt-td) {
    border-right: none;
    padding-left: 10px;
    overflow: unset;
  }

  :global(.ReactTable .rt-thead .rt-th.-sort-asc) {
    box-shadow: none;

    & > div:first-child:after {
      content: "⟱";
      font-size: 12px;
      position: absolute;
      top: 8px;
      margin-left: 10px;
    }
  }

  :global(.ReactTable .rt-thead .rt-resizable-header-content) {
    overflow: hidden;
  }

  :global(.ReactTable .pagination-bottom) {
    border-top: solid 1px rgba(var(--shadow-color-rgb), 0.05);
    background-color: var(--white-color);
    border-radius: 0 0 10px 10px;
  }

  :global(.ReactTable .rt-thead .rt-th.-sort-desc) {
    box-shadow: none;

    & > div:first-child:after {
      content: "⟰";
      font-size: 12px;
      position: absolute;
      top: 8px;
      margin-left: 10px;
    }
  }

  :global(.ReactTable .rt-tbody .rt-tr-group:last-child) {
    border-bottom: solid 1px rgba(var(--shadow-color-rgb), 0.05);
  }

  :global(.ReactTable .rt-tbody) {
    position: relative;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group) {
    max-height: 55px;
  }

  :global(.rt-tr-group) {
    font-size: 14px;
    padding: 5px 0;
  }

  .actionHeader {
    text-align: center;
  }
}

.checkboxTable {
  :global(.ReactTable .rt-thead .rt-th:first-child) {
    border-right: none;
    padding-left: 10px;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header:nth-child(2)) {
    padding-left: 10px;
  }
}

.checkboxTableResizable{
  :global(.ReactTable .rt-thead .rt-th:first-child) {
    padding-left: 10px;
  }
}
