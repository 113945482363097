* {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: var(--tertiary-color);
}
