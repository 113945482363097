.customLabelButton {
  transition: background-color linear 50ms;
  cursor: pointer;
  text-align: center;
  color: var(--white-color);
  min-width: 120px;
  padding: 5px 15px;
  font-weight: 500;
  border-radius: 4px;
  background-color: var(--text-body);
  border: var(--text-body);

  &:hover {
    background-color: var(--text-body-light-20);
  }

  &:active {
    background-color: var(--text-body-dark-20);
  }

  &:focus {
    outline: 0;
  }
}
