@import "../../scss/mixins/resolutionMixins";
.reportModal {
  :global(.modal-content) {
    background-color: var(--report-background-color);
    border-radius: 15px;
    height: 90vh;
    overflow: hidden;
    @include for-size(phone-only) {
      height: 90vh;
    }
  }

  :global(.modal-body) {
    background-color: var(--white-color);
    border-radius: 15px;
    padding-bottom: 50px;
    min-height: 100%;
  }

  .modalHeader {
    padding: 10px;
    display: flex;
    justify-content: center;
    height: calc(100% - 220px);
    position: absolute;
    width: 100%;
    top: 0;
    align-items: center;
  }

  .headerContent {
  }

  .modalBody {
    height: 100%;
  }
}
