.progressBarMain {
  :global(.progress) {
    height: 20px;
  }

  :global(.progress-bar) {
    background-color: var(--accent-color-light-20);
    transition: width 1s linear;
  }
}
