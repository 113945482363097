// Scrollbar Styling
@mixin scrollbar() {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
    background-color: rgba(var(--panel-background-rgb), 0.1);

    &:hover {
      background-color: rgba(var(--primary-color-rgb), 0.1);
    }
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(var(--natural-color-rgb), 0.5);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: rgba(var(--primary-color-rgb), 0.5);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-corner {
    display: none !important;
  }
}

* {
  @include scrollbar;
}
