.circleSvgMain {
  position: relative;
  .circleSvg {
    transform: rotate(-90deg);
    position: absolute;

    .progressBar {
      animation: moveLine 1s ease-in-out;
      fill: none;
      stroke: var(--primary-color);
      stroke-dasharray: 100 100;
      stroke-linecap: round;
      stroke-width: .8;
      stroke-dashoffset: 100
    }

    .secondaryColor {
      stroke: var(--secondary-color);
    }
  }

  @keyframes moveLine {
    from {
      stroke-dashoffset: 100;
    }
  }


}
