.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 17px;
  border-radius: 10px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1.5px solid transparent;
    &::before {
      position: absolute;
      content: '';
      height: 11px;
      width: 11px;
      bottom: 1.5px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      left: 2px;
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .disabled {
    cursor: not-allowed;
  }
}

.checked {
  border: 1.5px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  &:before {
    background-color: var(--white);
  }
}

.unchecked {
  border: 1.5px solid var(--natural-color) !important;

  &:before {
    background: var(--natural-color);
  }
}


