.headerWithSubHeader {
  text-align: center;

  .header {
    font-size: 25px;
    font-weight: 700;

  }

  .subHeader {
    font-size: 14px;
    line-height: 14px;
    color: var(--text-body);
    font-weight: 700;

  }
}
