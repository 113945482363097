.messageBase{
  background: var(--bg-color) !important;
  border-radius: 4px !important;
  min-height: 40px !important;
  padding: 0 !important;
}

.successBG {
  border-bottom: 6px solid var(--status-success);

}

.infoBG {
  border-bottom: 6px solid var(--primary-color);
}

.warningBG {
  border-bottom: 6px solid var(--status-warning);
}

.errorBG {
  border-bottom: 6px solid var(--status-danger);
}

.messageText {
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary);
  font-family: var(--font-family);
}
