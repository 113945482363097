.textWrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  opacity: 1 !important;

  :global(.tooltip-inner) {
    background-color: var(--tooltip-bg-color);
    border: 1px solid var(--tooltip-border-color);
  }

  &:global(.bs-tooltip-bottom .arrow:before) {
    border-bottom-color: var(--tooltip-border-color) !important;
  }

  &:global(.bs-tooltip-top .arrow:before) {
    border-top-color: var(--tooltip-border-color) !important;
  }
}
