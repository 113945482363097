@import "../../scss/mixins/resolutionMixins";

.card {
  padding: 25px 0;
  height: 120px;
  @include for-size(phone-only) {
    height: 95px;
    padding: 0 10px;
  }
}
