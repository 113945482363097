.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;

  input {
    border: none;
    cursor: pointer;
    height: 1rem;
    margin: 0;
    opacity: 0;
    padding: 0;
    width: 1rem;
  }

  input:checked + .checkmark:after {
    display: block;
  }

  input:disabled + .checkmark {
    background-color: var(--natural-color-light-80);
    border: 1px solid var(--border-color) !important;
    color: rgba(var(--contrast-color-rgb, white), 0.7);
    cursor: not-allowed;
  }

  .checkmark {
    align-items: center;
    border: 1px solid var(--primary-color);
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    height: 1rem;
    justify-content: center;
    width: 1rem;
  }

  .checkmark:after {
    background: var(--primary-color);
    border-radius: 3px;
    content: '';
    display: none;
    height: 0.6rem;
    width: 0.6rem;
  }

  .checkmark.indeterminate:after {
    border-radius: 2px;
    color: linear-gradient(var(--primary-color), var(--secondary-color));
    content: '';
    display: block;
    height: 4px;
    width: 0.6rem;
  }

  .label {
    display: inline;
    margin-left: 0.25rem;
    user-select: none;
  }
}
