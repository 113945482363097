.button {
  transition: background-color linear 50ms;
  padding: 5px 15px;
  font-weight: 500;
  font-color: var(--white-color);
  color: var(--white-color);
  border-radius: 4px;
  user-select: none;
  .btnSpinner {
    display: inline-block;
    overflow: hidden;
    width: 0;
    transition: width 300ms cubic-bezier(0.12, 0.96, 0.8, 1.04);
  }

  &.loading {
    .btnSpinner {
      overflow: unset;
      width: 20px;
      line-height: 0;
    }
  }

  &:disabled {
    background-color: var(--light-grey-color) !important;
    cursor: not-allowed;
  }

  &.primary {
    background-color: var(--secondary-color);
    border: var(--secondary-color);

    &:hover {
      background-color: var(--secondary-color-light-20);
    }

    &:active {
      background-color: var(--secondary-color-dark-20);
    }
    &:focus {
      outline: 0;
    }
  }

  &.secondary {
    background-color: var(--primary-color);
    border: var(--primary-color);

    &:hover {
      background-color: var(--primary-color-light-20);
    }

    &:active {
      background-color: var(--primary-color-dark-20);
    }

    &:focus {
      outline: 0;
    }
  }

  &.transparent {
    background-color: transparent;
    border: var(--white-color);

    &:focus {
      outline: 0;
    }
  }

  &.dark {
    background-color: var(--text-body);
    border: var(--text-body);

    &:hover {
      background-color: var(--text-body-light-20);
    }

    &:active {
      background-color: var(--text-body-dark-20);
    }

    &:focus {
      outline: 0;
    }
  }
}
