@import "../../scss/mixins/resolutionMixins";

.countColumn {
  text-align: center;

  .countText {
    font-size: 32px;
    font-weight: 400;
    color: var(--primary-color);
    @include for-size(phone-only) {
      font-size: 24px;
    }
  }

  .titleText {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: var(--text-body);
    @include for-size(phone-only) {
      font-size: 13px;
    }
  }

}
