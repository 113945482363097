.noData {
  padding: 25px;

  .title {
    font-size: 15px;
    font-weight: 400;
    opacity: 0.5;
    color: var(--text-body);
  }

  .boxIcon {
    font-size: 35px;
    color: var(--text-body);
    opacity: 0.2;
  }
}
