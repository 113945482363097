.title{
  font-size: 16px;
  color: var(--white-color);
}

.container{
  margin: 5px 0;
  padding: 15px;
  background-color: var(--white-color);
  border-radius: 20px;
  height: 125px;
  justify-content: space-around;
  align-items: center;
}
