.uploadImageMain {
  transition: all 100ms ease-in-out;
  color: var(--text-primary);
  width: 200px;
  margin: 10px 0;

  & > label {
    user-select: none;
    cursor: pointer;
  }
  .uploadImage {
    transition: all 100ms ease-in-out;
    border: solid 2px var(--text-primary);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 16px;
  }

  &:hover, &:hover > .uploadImage {
    color: var(--text-primary-light-20);
    border-color: var(--text-primary-light-20);;
  }

  .uploadText {
    margin-left: 10px;
    text-decoration: underline;
  }

}
