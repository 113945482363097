.navIcon {
  width: 25px;
  &:after,
  &:before,
  & div {
    background-color: #fff;
    border-radius: 3px;
    content: "";
    display: block;
    height: 3px;
    margin: 5px 0;
    transition: all 0.2s ease-in-out;
  }


  &.active:before {
    transform: translateY(9px) rotate(135deg);
  }

  &.active:after {
    transform: translateY(-7px) rotate(-135deg);
  }

  &.active div {
    transform: scale(0);
  }
}



