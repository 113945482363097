.bottomButton {
  margin-top: 10px;
  .mainBtn {
    color: var(--white);
    width: 100% !important;
  }
  .secondaryBtn {
    color: var(--text-placeholder);
    text-align: center;
  }
}
