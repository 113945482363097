.content {
  text-align: center;
  color: var(--natural-color);
  font-size: 14px;
  user-select: none;
  & > span {
    font-weight: 800;
  }
  .primaryText {
    font-size: 22px;
    color: var(--primary-color);
  }

  .secondaryColor {
    color: var(--secondary-color);
  }

  .separator {
    margin: 0 3px;
  }
}
.progressContent {
  margin-bottom: 5px;
  width: 60px;
  .progressFont {
    font-size: 11px;

    .progress {
      color: var(--primary-color);
      font-weight: 900;
      font-size: 11px;
    }
  }
}
