.competencyScore {
  .score {
    border-radius: 50%;
    background-image: linear-gradient(
      var(--tertiary-color),
      var(--primary-color)
    );
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;

    .scoreNumber {
      color: var(--white-color);
      font-size: 32px;
    }
  }

  .redBorder {
    border: 3px solid var(--secondary-color);
  }

  .name {
    display: flex;
    text-align: center;
    font-size: 12px;
    color: var(--text-body-dark-20);
    width: 100px;
    height: 50px;
  }

  .buttonContainer {
    font-size: 12px;

    .button {
      border: 1px solid var(--secondary-color);
      background-color: transparent;
      color: var(--secondary-color);
      padding: 3px 7px;

      &:hover {
        color: var(--secondary-color-light-20);
        background-color: transparent;
        border: 1px solid var(--secondary-color-light-20);
      }

      &:active {
        color: var(--secondary-color-dark-20);
        background-color: transparent;
        border: 1px solid var(--secondary-color-dark-20);
      }

      &:disabled {
        color: var(--dark-grey-color);
        background-color: transparent;
        border: 1px solid var(--dark-grey-color);
      }
    }
  }
}
